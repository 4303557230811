[class^="icon-"],
[class*=" icon-"] {
    vertical-align: middle;
    margin: 5px;
    cursor: pointer;
}

.grey {
    fill: #dbdee4;
}

.white {
    fill: #ffffff;
}

.blue {
    fill: #0e76a8;
}

.red {
    fill: red;
}

.dark {
    fill: #061A11;
}

.primary {
    fill: #547d13;
}

.small {
    height: 22px;
    width: 22px;
    vertical-align: middle;
}

.extra-small {
    height: 17px;
    width: 17px;
    vertical-align: middle;
}

.big {
    height: 32px;
    width: 32px;
    vertical-align: middle;
}

.clickable:hover {
    background: #dbdee4;
    fill: #ffffff;
}