.bg-white {
    background: #ffffff;
    background-color: #ffffff;
}

.bg-grey {
    background: #d5d5d5;
    background-color: #d5d5d5;
}

.color-grey {
    color: #d5d5d5;
}

.bg-primary-color {
    background-color: #24C86F;
    background: #24C86F;
}

.color-primary {
    color: #24C86F;
}

.bg-accent-color {
    background-color: #FFFA9C;
    background: #FFFA9C;
}

.color-accent {
    color: #FFFA9C;
}

.bg-primary-dark {
    background-color: #061A11;
    background: #061A11;
}

.color-primary-dark {
    color: #061A11;
}

.horizontal-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.horizontal-list li {
    float: left;
}

.delete-and-edit-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.delete-and-edit-list li {
    float: left;
}

.horizontal-list li a {
    display: block;
    text-align: center;
    padding: 5px;
    text-decoration: none;
    color: #000000;
}

.title-text {
    color: #061A11;
}

.subtitle-text {
    color: #6c757d!important;
}

a {
    color: #24C86F;
    text-decoration: none;
    background-color: transparent;
}

.horizontal-list li a:hover {
    background-color: #d5d5d5;
}

.horizontal-list .active-link {
    color: #1da1f2;
}

.card-1 {
    -webkit-box-shadow: 0 0.4rem 1.2rem 0.2rem rgba(0, 0, 0, .05);
    /* box-shadow: 0 0.4rem 1.2rem 0.2rem rgba(0, 0, 0, .05); */
    /* box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05), 0 1px 2px 0 rgba(0, 0, 0, 0.05); */
    /* -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s; */
    /* -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s; */
    /* transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s; */
    background: #ffffff;
    /* border: .2px solid #ddd; */
    /* border-top: none; */
    box-sizing: border-box;
    /* min-height: 100px; */
    border-radius: 8px;
    box-shadow: 0 2px 10px 0 #e4e8f0;
    border: solid 1px #e8e8e8;
}

.navbar {
    border-radius: 0px;
}

.card-2 {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    background: #ffffff;
}

.card-shadow {
    box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
}

label {
    font: 400;
}

.is-invalid {
    border: 0.5px solid orangered;
}

.invalid-feedback {
    color: orangered;
}

.google-button {
    height: 40px;
    border-width: 0;
    background: orangered;
    color: #ffffff;
    border-radius: 5px;
    white-space: nowrap;
    box-shadow: 1px 1px 0px 1px rgba(0, 0, 0, 0.05);
    transition-property: background-color, box-shadow;
    transition-duration: 150ms;
    transition-timing-function: ease-in-out;
    padding: 0;
    margin: 5px 0 10px 0;
}

.facebook-button {
    height: 40px;
    border-width: 0;
    background: #3B5998;
    color: #ffffff;
    border-radius: 5px;
    white-space: nowrap;
    box-shadow: 1px 1px 0px 1px rgba(0, 0, 0, 0.05);
    transition-property: background-color, box-shadow;
    transition-duration: 150ms;
    transition-timing-function: ease-in-out;
    padding: 0;
    margin: 5px 0 10px 0;
}

.linkedIn-button {
    height: 40px;
    border-width: 0;
    background: #1da1f2;
    color: #ffffff;
    border-radius: 5px;
    white-space: nowrap;
    box-shadow: 1px 1px 0px 1px rgba(0, 0, 0, 0.05);
    transition-property: background-color, box-shadow;
    transition-duration: 150ms;
    transition-timing-function: ease-in-out;
    padding: 0;
    margin: 5px 0 5px 0;
}

.twitter-button {
    height: 40px;
    border-width: 0;
    background: #1da1f2;
    color: #ffffff;
    border-radius: 5px;
    white-space: nowrap;
    box-shadow: 1px 1px 0px 1px rgba(0, 0, 0, 0.05);
    transition-property: background-color, box-shadow;
    transition-duration: 150ms;
    transition-timing-function: ease-in-out;
    padding: 0;
    margin: 5px 0 5px 0;
}

.google-button__icon {
    display: inline-block;
    vertical-align: middle;
    margin: 8px 0 8px 8px;
    width: 18px;
    height: 18px;
    box-sizing: border-box;
}

.google-button__icon--plus {
    width: 27px;
}

.google-button__text {
    display: inline-block;
    vertical-align: middle;
    padding: 0 24px;
    font-size: 14px;
    font-weight: bold;
    font-family: 'Roboto', arial, sans-serif;
}

.btn-facebook {
    background: #3B5998;
    border-radius: 4px;
    height: 40px;
    color: #fff;
    border-width: 1px;
    border-style: solid;
    border-color: #263961;
}

.btn-facebook:link,
.btn-facebook:visited {
    color: #fff;
}

.btn-facebook:active,
.btn-facebook:hover {
    background: #263961;
    color: #fff;
}

.text-with-line {
    display: grid;
    width: 100%;
    align-items: center;
    text-align: center;
    grid-template-columns: minmax(10px, 1fr) auto minmax(10px, 1fr);
    grid-gap: 8px;
}

.text-with-line:before,
.text-with-line:after {
    content: '';
    border: .2px solid #d5d5d5;
}

.sidenav {
    /* max-width: 260px; */
    position: fixed;
    z-index: 1;
    overflow-x: hidden;
    /* width: 240px; */
}

.content-middle {
    margin-left: 200px;
    /* Same width as the sidebar + left position in px */
    padding: 5px 10px;
    position: relative;
}


/* Extra small devices (portrait phones, less than 576px) */

@media (max-width: 575.98px) {
    .sidenav {
        position: relative;
    }
}


/* Small devices (landscape phones, 576px and up) */

@media (min-width: 576px) and (max-width: 767.98px) {
    .sidenav {
        display: none;
    }
}


/* Medium devices (tablets, 768px and up) */

@media (min-width: 768px) and (max-width: 991.98px) {}


/* Large devices (desktops, 992px and up) */

@media (min-width: 992px) and (max-width: 1199.98px) {}


/* Extra large devices (large desktops, 1200px and up) */

@media (min-width: 1200px) {}

.profile-image-wrapper {
    text-align: center;
    position: relative;
}

.profile-image-small {
    border: 1px solid #d5d5d5;
    border-radius: 50%;
    width: 35px;
}

.profile-image-medium {
    border: 1px solid #d5d5d5;
    border-radius: 50%;
    width: 100px;
}

.profile-image-large {
    border: 1px solid #d5d5d5;
    border-radius: 50%;
    width: 100px;
}

.sidenav-menu {
    list-style-type: none;
    overflow: hidden;
    padding: 0;
}

.sidenav-menu li {
    padding: 0;
    margin: 5px 0;
}

.sidenav .menu-item {
    /* background: #dbdee4; */
    position: relative;
}

.word-warp {
    word-wrap: break-word;
    white-space: nowrap;
    overflow-wrap: break-word;
}

.sidenav-menu li a {
    text-decoration: none;
    font-size: 14px;
}

.sidenav-menu li:hover {
    background-color: white;
}

.sidenav-menu .icon {
    padding: 10px;
    font-size: 18px;
    color: #24C86F;
    vertical-align: middle;
}

.divider {
    border-bottom: .2px solid #d5d5d5;
    margin: 5px 0 5px 0px;
    width: 100%;
}

.divider-vertical {
    padding: 0px;
    border-left: 1px solid #d5d5d5;
    border-right: 1px solid #d5d5d5;
}

.verticalSpacing {
    width: 1px;
    height: 60%;
    background: #dddddd;
    margin-right: 15px;
}

.sidenav-menu-active {
    background-color: #486911;
    color: white !important;
    border-radius: 20px;
}

.active-indicator {
    position: absolute;
    width: 15px;
    right: 0;
    top: 0;
    height: 100%;
    /* background: #24C86F; */
    color: #24C86F;
    text-align: center;
    /* font-size: 38px; */
    display: none;
    padding-top: 3px;
}

.sidenav-menu-active+.active-indicator {
    display: block;
}

.sidenav-right {}

.sticky-top {
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -ms-sticky;
    position: -o-sticky;
    position: sticky;
    top: 50px;
}

@supports(position: sticky) {
    .sticky-top {
        position: -webkit-sticky;
        position: -moz-sticky;
        position: -ms-sticky;
        position: -o-sticky;
        position: sticky;
        top: 50px;
    }
}

/* .event-poster {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
} */

.event-poster-lg {
    object-fit: cover;
}

.event-list {
    padding: 10px;
    list-style-type: none;
    overflow: hidden;
}

.caret-left {
    cursor: pointer;
}

.link {
    cursor: pointer;
}

.link:link,
.link:visited {
    text-decoration: none;
}

.navbar-toggler {
    border: none;
}

.btn-default {
    color: #fff;
    background-color: #24C86F;
    border-color: #ccc;
}

.btn-default.focus,
.btn-default:focus {
    color: #ffffff;
    background-color: #85C225;
    border-color: #ccc;
}

.btn:focus {
    box-shadow: 0 2px 10px 0 #e4e8f0;
}

.btn-default:hover {
    color: #ffffff;
    background-color: #85C225;
    border-color: #ccc
}

.btn-default.active,
.btn-default:active,
.open>.dropdown-toggle.btn-default {
    color: #ffffff;
    background-color: #24C86F;
    background-image: none;
    border-color: #ccc;
}

.btn-default.active.focus,
.btn-default.active:focus,
.btn-default.active:hover,
.btn-default:active.focus,
.btn-default:active:focus,
.btn-default:active:hover,
.open>.dropdown-toggle.btn-default.focus,
.open>.dropdown-toggle.btn-default:focus,
.open>.dropdown-toggle.btn-default:hover {
    color: #ffffff;
    background-color: #85C225;
    border-color: #8c8c8c;
}

.btn-default.disabled.focus,
.btn-default.disabled:focus,
.btn-default.disabled:hover,
.btn-default[disabled].focus,
.btn-default[disabled]:focus,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default.focus,
fieldset[disabled] .btn-default:focus,
fieldset[disabled] .btn-default:hover {
    background-color: #85C225;
    border-color: #ccc
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #ECEEF0;
    background-clip: padding-box;
    border: 1px;
    border-radius: .25rem;
    /* transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out; */
}

.form-control:focus {
    color: #495057;
    background-color: #ECEEF0;
    border-color: none;
    outline: 0;
    /* box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25) */
    box-shadow: 0 2px 10px 0 #e4e8f0;
}

.input-group-text {
    display: flex;
    align-items: center;
    padding: .375rem .75rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    /* background-color: #e9ecef; */
    border: 1px;
    border-radius: .25rem;
}

.alert-bar {
    box-shadow: 0 2px 10px 0 #e4e8f0;
    font-size: 14px;
    background: #061A11;
}

.alert-bar.top {
    height: 35px;
    padding-top: 7.5px;
    width: 100%;
    text-align: center;
    color: #ffffff;
}

.alert-dialog {
    text-align: center;
}

.alert-dialog-icon {
    margin: auto;
    height: 75px;
    width: 75px;
    border-radius: 50%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.error-alert {
    background-color: red;
    /* padding: 10px; */
}

.alert-dialog .modal-header1 {
    text-align: center;
    padding-top: 10px;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: .3rem;
    border-top-right-radius: .3rem;
}

.btn-outline-primary {
    color: #24C86F;
    border-color: #24C86F
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: #24C86F;
    border-color: #24C86F
}

.btn-outline-primary.focus,
.btn-outline-primary:focus {
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .5)
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: #24C86F;
    background-color: transparent
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show>.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #24C86F;
    border-color: #24C86F
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .5)
}


/* .event-card{
    position: relative;
}
.event-card .event-poster{
    z-index: 100;
}
.event-card .img-placeholder {
    position: absolute;
    top: 0;
    left: 0;
    height: 50%;
    width: 100%;
    background: #dbdee4;
    background-image: url ("../img/event-img-placeholder.jpg");
} */

.mentorship-request {
    border: .5px solid #d5d5d5;
    border-radius: 3px;
}

.home-login-btn{
    background-color: #24C86F;
    height: 44px;
    width: 120px;
    text-align: center;
    font-size: 18px;
    color: #ffffff;
    border-radius: 12px;
}

.spz-button-green{
    background-color: #24C86F;
    min-height: 35px;
    min-width: 120px;
    text-align: center;
    font-size: 18px;
    color: #ffffff;
    border-radius: 12px;
    font-size: 15px;
}

.spz-button-outline-white{
    background-color: transparent;
    border-color: #ffffff;
    border: 1px solid #ffffff;
    min-height: 35px;
    min-width: 120px;
    text-align: center;
    font-size: 18px;
    border-radius: 12px;
}

.spz-button-outline-green{
    background-color: transparent;
    border-color: #24C86F;
    border: 1px solid #24C86F;
    min-height: 35px;
    min-width: 120px;
    text-align: center;
    font-size: 18px;
    border-radius: 12px;
}

.spz-button-outline-black{
    border-color: #000000;
    border: 1px solid #000000;
    min-height: 35px;
    min-width: 120px;
    text-align: center;
    font-size: 18px;
    border-radius: 12px;
}

.color-spz-green{
    color: #24C86F;
}

.color-white{
    color: #ffffff !important;
}

.color-spz-grey{
    color: #8b8fa7;
}

.color-spz-grey-2{
    color: #CAD3D9;
}

.bg-spz-grey-2{
    background-color: #eceeef;
}

.goal-card{
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}

/*-------------------------*/