/* You can add global styles to this file, and also import other style files */

@import 'assets/css/animate.css';
@import 'assets/css/icons-sheet.css';
@import 'assets/css/font-awesome.min.css';
@import 'assets/css/custom_style.css';
@import 'assets/css/sidebar.css';
@import '~swiper/dist/css/swiper.min.css';
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

/* devanagari */

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLBT5Z11lFd2JQEl8qw.woff2) format('woff2');
    unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}


/* latin-ext */

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLBT5Z1JlFd2JQEl8qw.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}


/* latin */

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/poppins/v15/pxiByp8kv8JHgFVrLBT5Z1xlFd2JQEk.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


/* @font-face {
    font-family: HurmeGeometricSans4-Regular;
    src: url(./assets/fonts/HurmeGeometricSans4.otf);
} */

@font-face {
    font-family: Raleway-Medium;
    src: url(assets/fonts/Raleway-Medium.ttf);
}

html,
body {
    height: 100%;
    background: #f9f9f9;
    position: relative;
    min-height: 100%;
    color: #061A11;
}

.popover {
    max-width: 100% !important;
}

body {
    margin: 0;
    font-family: Raleway-Medium, Roboto, sans-serif;
    /* padding-top: 55px; */
    padding: 0;
}